<template>
  <v-banner
    v-if="showBanner"
    color="white"
    class="cookie-banner"
    dense
  >
    <template #icon>
      <v-icon
        color="secondary"
        class="icon"
      >
        mdi-cookie
      </v-icon>
    </template>
    <div class="text-content">
      <div class="secondary--text text-h5">
        {{ $t('cookies.title') }}
      </div>
      <div class="secondary--text py-4 body-text">
        {{ $t('cookies.text') }}<a
          :href="privacyPolicyUrl"
          target="_blank"
          class="primary--text text--underline"
        > {{ $t('cookies.privacyPolicy') }} </a> {{ $t('cookies.moreInfo') }}
      </div>
    </div>
    <div class="d-flex justify-start button-container">
      <v-btn
        class="primary font-weight-bold button"
        @click="allowAll"
      >
        {{ $t('cookies.allowButton') }}
      </v-btn>
      <v-btn
        class="accent black--text font-weight-bold button"
        @click="allowNecessary"
      >
        {{ $t('cookies.rejectButton') }}
      </v-btn>
    </div>
  </v-banner>
</template>

<script>
  export default {
    name: 'CookieBanner',

    data () {
      return {
        showBanner: !localStorage.getItem('cookie_preference_set'),
      }
    },
    computed: {
      currentCountry () { return this.$store.getters.getWebsiteLocation },
      privacyPolicyUrl () { return this.currentCountry === 'US' ? '/privacy' : '/privacy-ca' }
    },

    mounted () {
      const preference = localStorage.getItem('cookie_preference')
      if (preference === 'all') {
        this.enableAnalytics()
      } else {
        this.disableAnalytics()
      }
    },
    methods: {
      enableAnalytics () {
        this.$gtag.optIn()
      },

      disableAnalytics () {
        this.$gtag.optOut()
      },

      allowAll () {
        localStorage.setItem('cookie_preference', 'all')
        localStorage.setItem('cookie_preference_set', 'true')
        this.showBanner = false
        this.enableAnalytics()
      },

      allowNecessary () {
        localStorage.setItem('cookie_preference', 'functional')
        localStorage.setItem('cookie_preference_set', 'true')
        this.showBanner = false
        this.disableAnalytics()
      },
    },
  }
</script>

<style scoped>
.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 2000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1em;
}

.icon {
  font-size: 2em;
}

.text-content {
  flex-grow: 1;
}

.headline {
  font-size: 1.2em;
}

.body-text {
  font-size: 0.9em;
  white-space: normal;  /* Ensures the text wraps and doesn't get cut off */
}

.button-container {
  flex-direction: column;
}

.button {
  margin-top: 0.5em;
  font-size: 0.9em;
}

/* Adjustments for larger screens */
@media only screen and (min-width: 600px) {
  .headline {
    font-size: 1.5em;
  }

  .body-text {
    font-size: 1em;
  }

  .button-container {
    flex-direction: row;
  }

  .button {
    margin-top: 0;
    font-size: 1em;
  }
}
</style>
